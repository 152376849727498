<template>
  <footer class="footer">
    <div class="row">
      <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="col text-center">
        <span class="fas fa-map-marker-alt"></span>
        <span class="footer-headline">Geschäftsadresse</span>
        <p class="p-unstyled">
          {{contentFooter.Adresse.Straße}} {{contentFooter.Adresse.Hausnummer}} <br> {{contentFooter.Adresse.PLZ}} {{contentFooter.Adresse.Stadt}}
        </p>
      </div>
      </div>

      <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="col text-center">
        <span class="fas fa-envelope"></span>
        <span class="footer-headline">E-Mail/Fax</span>
        <p class="p-unstyled">{{contentFooter.EMail}} <br> {{contentFooter.Fax}}</p>
      </div>
      </div>

      <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="col text-center">
        <span class="fas fa-phone"></span>
        <span class="footer-headline">Telefonnummer</span>
        <p class="p-unstyled">{{contentFooter.Telefonnummer}}</p>
      </div>
      </div>

      <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="col text-center">
        <span class="far fa-clock"></span>
        <span class="footer-headline">Erreichbarkeit</span>
        <p class="p-unstyled">{{contentFooter.Erreichbarkeit}} <br> und nach Vereinbarung</p>
      </div>
      </div>
    </div>
    <ScrollTopButton/>
  </footer>
</template>

<script>
import ScrollTopButton from './ScrollTopButton.vue'

export default {
  name: 'Footer',
  props: ['contentFooter'],
  components: {
    ScrollTopButton
  }
}
</script>

<style lang="scss">
  @import '../assets/scss/base';

  .footer {
    font-family: $font-nav;
    padding: 15px 0;
    background-color: $color-main;
    color: $color-white;
    position: relative; //scroll-button
    display: block;
    width: 100%;

    .fas, .far {
      display: block;
      font-size: 3em;
      margin-bottom: 12px;
    }

    p {
      color: $color-white;
      display: block;
      font-size: 1.6rem;
      line-height: 23px;
      margin-top: 8px;
    }

    .row {
      margin: 0;
    }
  }

  .footer-headline {
    display: block;
    font-size: 2rem;
    line-height: 20px;
    margin-bottom: 0;
  }

  //_____________________________________________________Smartphone
  @media (max-width: $screen-md) {
    .footer {
      padding: 20px 0 10px 0;

      .fas, .far {
        font-size: 2.4rem;
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 20px;
        font-size: 1.4rem;
      }
    }
    .footer-headline {
      font-size: 1.8rem;
    }
  }

  @media (max-width: $screen-sm) {
  .container {
    width: 90%;
  }
}
</style>
