<template>
  <div>
    <section
        v-for="(contentSec, index) in mainContent"
        :key="contentSec.Ueberschrift"
        :id="removeWhitespace(contentSec.Ueberschrift)"
        :class="'main-content '+ (index%2 == 0 ? 'primary' : 'secondary')">
      <div class="container animation-element" v-if="contentSec.BilderSerie">
        <h2 class="text-center">{{contentSec.Ueberschrift}}</h2>
        <p class="fliestext" v-if="contentSec.Fliesstext !== ''" v-html="contentSec.Fliesstext"></p>
        <ImageContainer :imageContent ="contentSec.BilderSerie"/>
      </div> <!-- container -->

      <div class="container animation-element" v-else>
        <!-- left side -->
        <div class="row" v-if="index%2 == 1">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="col text-left text-section">
              <h2>{{contentSec.Ueberschrift}}</h2>
              <p class="fliestext" v-if="contentSec.Fliesstext !== ''" v-html="contentSec.Fliesstext"></p>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 hidden-mobile">
            <a class="a-unstyled gallery" :href="'/img/'+contentSec.Bild.BildlinkGroß">
              <img class="right img-text" :src="'/img/'+contentSec.Bild.BildlinkKlein" :alt="contentSec.Bild.BildInhaltkurz">
            </a>
          </div>
        </div> <!-- row -->
        <!-- right side -->
        <div class="row" v-else>
          <div class="col-sm-12 col-md-6 col-lg-6 hidden-mobile">
            <a class="a-unstyled gallery" :href="'/img/'+contentSec.Bild.BildlinkGroß">
              <img class="left img-text" :src="'/img/'+contentSec.Bild.BildlinkKlein" :alt="contentSec.Bild.BildInhaltkurz">
            </a>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="col text-section">
              <h2>{{contentSec.Ueberschrift}}</h2>
              <p class="fliestext" v-if="contentSec.Fliesstext !== ''" v-html="contentSec.Fliesstext"></p>
            </div>
          </div>
        </div> <!-- row -->
      </div> <!-- container -->
    </section> <!-- main-content-->
  </div>
</template>

<script>
import ImageContainer from './ImageContainer.vue'

export default {
  name: 'Header',
  props: ['mainContent'],
  components: {
    ImageContainer
  },
  methods: {
    removeWhitespace: function (str) {
      return str.trim().replace(/\s/g, '')
    }
    // getImgUrl (pic) {
    //   var images = require.context('../assets/img/', false, /\.jpg$/)
    //   return images('./' + pic)
    // }
  }
}
</script>

<style lang="scss">
  @import '../assets/scss/base';

  .main-content {
    padding: 80px 0;
    position: relative;

    &.welcome {
      background-color: $color-background2 !important;
      padding-top: 80px;
    }

    &.secondary {
      background-color: $color-background2;
    }
  }

  .text-section {
    padding: 10px;

    h2 {
      margin: 0 0 40px; 
    }
  }

  .img-text {
    // box-shadow: 0 5px 15px -8px rgba(0,0,0,.24), 0 8px 10px -5px rgba(0,0,0,.2);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    cursor: zoom-in;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    width: 80%;

    &.right {
      margin-right: 10px;
    }

    &.left {
      margin-left: 10px;
    }

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }

  //_____________________________________________________Tablet
    @media (min-width: 1800px) {
    .main-content {
      padding: 90px 0;
    }
  }

  @media (max-width: $screen-lg) {
    .main-content {
      padding: 40px 0;
    }
  }

  //_____________________________________________________Smartphone
  @media (max-width: $screen-md) {
    .main-content {
      padding: 50px 0;

      &.welcome {
        padding-top: 50px;

        & > .container {
          width: 70%;
        }
      }
    }
  }

  @media (max-width: $screen-sm) {
    .main-content {
      padding: 40px 0;

      &.welcome {
        padding-top: 40px;

        & > .container {
          width: 90%;
        }
      }
    }

    .text-section {
      padding: 10px;

      h2 {
        margin: 0 0 20px; 
      }
    }
  }

</style>
