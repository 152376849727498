<template>
  <div id="app">
    <div class="load-container">
      <div class="load-eclipse">
        <div></div>
      </div>
    </div>
    <Navigation :contentNavigation="$options.content.Inhalt"/>
    <Header :contentHeader="$options.content.Kopfbereich"/>
    <main class="main">
      <IntroSection :introContent ="$options.content.Startinhalt"/>
      <ContentSections :mainContent ="$options.content.Inhalt"/>
    </main>
    <Footer :contentFooter ="$options.content.Fusszeile"/>
    <Impressum :impressumContent ="$options.content.Impressum"/>
  </div>
</template>

<script>  
import content from './content.json'
import Navigation from './components/Navigation.vue'
import Header from './components/Header.vue'
import IntroSection from './components/IntroSection.vue'
import ContentSections from './components/ContentSections.vue'
import Impressum from './components/Impressum.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  content: content,
  components: {
    Navigation,
    Header,
    IntroSection,
    ContentSections,
    Impressum,
    Footer
  },
  mounted () {
    this.$nextTick(() => {
      // _________________________________________________Animation
      var animationElements = $('.animation-element')
      var screen = $(window)

      function checkIfInView () {
        var windowHeight = screen.height()
        var windowTopPosition = screen.scrollTop()
        var windowBottomPosition = (windowTopPosition + windowHeight)

        $.each(animationElements, function () {
          var element = $(this)
          var elementTopPosition = element.offset().top + 50

          // check to see if this current container is within viewport
          if ((elementTopPosition <= windowBottomPosition)) {
            element.addClass('animated zoomIn')
          }
        })
      }

      screen.on('scroll resize', checkIfInView)
      screen.trigger('scroll')

      // _________________________________________________Gridsystem
      var anzColumns = 12
      var width = 0
      var columnSelector = ''
      var label = ''
      var labelOffset = ''
      var screenSm = 650
      var screenMd = 870

      function initColumns () {
        for (var i = 1; i < (anzColumns + 1); i++) {
          columnSelector = label + '' + i
          width = (100 / anzColumns) * i
          width = width + '%'
          $(columnSelector).css('width', width)
        }
      }

      function initOffset () {
        for (var i = 1; i < (anzColumns + 1); i++) {
          columnSelector = labelOffset + '' + i
          width = (100 / anzColumns) * i
          width = width + '%'
          $(columnSelector).css('margin-left', width)
        }
      }

      function initLabel () {
        if ((($(window).width()) > 0) && (($(window).width()) <= screenSm)) {
          label = '.col-sm-'
          labelOffset = '.col-sm-offset-'
        }

        if ((($(window).width()) > screenSm) && (($(window).width()) <= screenMd)) {
          label = '.col-md-'
          labelOffset = '.col-md-offset-'
        }

        if (($(window).width()) > screenMd) {
          label = '.col-lg-'
          labelOffset = '.col-lg-offset-'
        }
      }

      initLabel()
      initColumns()
      initOffset()

      var debounceTimer
      $(window).resize(function () {
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(function () {
          initLabel()
          initColumns()
          initOffset()
        }, 100)
      })
    })
  }
}
</script>

<style lang="scss">
@import 'assets/scss/base';
@import 'assets/scss/common';
@import 'assets/scss/typografie';
@import 'assets/scss/gridsystem';
@import 'assets/scss/animated';
</style>
