<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4" v-for="image in imageContent" :key="image.ID">
      <div class="img-container col">
        <!-- <a class="a-unstyled gallery" :href="getImgUrl(image.BildlinkGroß)"> -->
        <a class="a-unstyled gallery" :href="'/img/'+image.BildlinkGroß">
          <img :src="'/img/'+image.BildlinkKlein" :alt="image.BildInhaltkurz">
          <div class="img-container-title">
            <span>{{image.BildUnterschrift}}</span>
            <span class="fas fa-search"></span>
          </div>
          <div class="img-container-content">
            <p class="p-unstyled fliestext-img">{{image.BildInhalt}}</p>
          </div>
        </a>
      </div>
    </div>
  </div> <!-- row -->
</template>

<script>
export default {
  name: 'ImageContainer',
  props: ['imageContent'],
  methods: {
    // getImgUrl (pic) {
    //   var images = require.context('../assets/img/', false, /\.jpg$/)
    //   return images('./' + pic)
    // }
  },
  mounted () {
    this.$nextTick(() => {
      $(document).ready(function () {
        let screenWidth = ($(window).width() * 85) / 100
        let screenHeight = ($(window).height() * 85) / 100
        $('a.gallery').colorbox({
          opacity: 0.8,
          rel: 'group2',
          scalePhotos: true,
          returnFocus: true,
          maxWidth: screenWidth,
          maxHeight: screenHeight
        })

        $('#cboxClose').addClass('far').addClass('fa-window-close')
        $('#cboxNext').addClass('fas').addClass('fa-angle-right')
        $('#cboxPrevious').addClass('fas').addClass('fa-angle-left')
      })
    })
  }
}
</script>

<style lang="scss">
  @import '../assets/scss/base';

  //_________________________________________________img-container
  .img-container {
    box-shadow: 0 5px 15px -8px rgba(0,0,0,.24), 0 8px 10px -5px rgba(0,0,0,.2);
    display: inline-block;
    font-family: $font-main, $font-default;
    overflow: hidden;
    position: relative;

    .fa-search {
      visibility: hidden;

      &::before {
        content: "\f002";
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        right: 30px;
        font-size: 2rem;
      }
    }

    &:hover {
      .img-container-content {
        max-height: 100%;
      }

      .img-container-title {
        background-color: $color-main;

        .fa-search {
          visibility: visible;
        }

        span {
          color: $color-white;
        }
      }
    }

    a {
      display: block;
      font-size: 0;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .fliestext-img {
    color: $color-fliestext-teaser;
    font-size: 1.5rem;
    font-family: $font-main, $font-default;
    line-height: 21px;
  }

  .img-container-title {
    background-color: rgba(255, 255, 255, .7);
    bottom: 0;
    display: table;
    height: 50px;
    position: absolute; //.img-container
    transition: background .4s ease;
    width: 101%;

    span {
      color: $color-fliestext;
      display: table-cell;
      font-size: 2rem;
      line-height: 18px;
      text-align: center;
      vertical-align: middle;
    }
  }

  .img-container-content {
    background-color: $color-img-overlay;
    bottom: 50px;
    left: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;  //.img-container
    right: 0;
    transition: max-height .4s ease;

    p {
      color: $color-white;
      padding: 30px 20px;
    }
  }

  #cboxOverlay {
    background: $color-black;
  }


  // ______________________Smartphone
  @media (max-width: $screen-md) {
    .img-container {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      border-radius: 4px;

      & p:last-of-type {
        padding-bottom: 0;
      }

      &:hover {
        .img-container-title {
           background-color: transparent;

          span {
            color: $color-fliestext;
          }
        }
      }

      a {
        background-color: $color-white;

        img {
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.11);
        }
      }

      .fa-search {
        display: none;
      }
    }

    .img-container-title {
      background-color: transparent;
      bottom: auto;
      font-weight: 500;
      height: 40px;
      padding: 12px 0 0px 0;
      position: static;
      transition: none;

      span {
        font-size: 1.8rem;
        padding: 0 16px;
        text-align: left;
      }
    }

    .img-container-content {
      background-color: transparent;
      bottom: auto;
      left: auto;
      max-height: 100%;
      padding-bottom: 24px;
      position: static;
      transition: none;

      p {
        color: $color-fliestext;
        padding: 0px 16px 20px 16px;
      }
    }
  }
  @media (max-width: $screen-sm) and (min-width: $screen-xs) {
    .img-container {
      display: block;
      margin: 10px auto !important;
      width: 80%;
    }
  }

  @media (max-width: $screen-xs) {

  }

  // _________________________________________________Colorbox
  #cboxNext, #cboxPrevious {
    background: none;
    color: $color-accordion-background2;
    font-size: 70px;
    outline: none;
    height: 70px;
    width: 50px;

    &:hover {
      color:  $color-white;
    }
  }

  #cboxPrevious {
    left: -80px;
  }

  #cboxNext {
    right: -80px;
  }

  #cboxContent {
    background: none;

    & > .fa-angle-left::before {
      left: 10px;
      position: absolute;
      text-indent: 0;
      top: 0;
    }

    & > .fa-angle-right::before {
      position: absolute;
      right: 10px;
      text-indent: 0;
      top: 0;
    }

    & > .fa-window-close::before {
      position: absolute;
      right: 4px;
      text-indent: 0;
      top: 0;
    }
  }

  #cboxLoadedContent {
    border: none;
  }

  #cboxCurrent {
    display: none !important;
  }

  #cboxClose {
    background: none;
    color: $color-accordion-background2;
    font-size: 35px;
    height: 30px;
    right: -60px;
    top: -50px;
    width: 30px;

    &:hover {
      color: $color-closebutton;
    }
  }

  #colorbox, #cboxOverlay, #cboxWrapper {
    overflow: unset;
  }

  @media (max-width: $screen-sm) {
    #colorbox, #cboxOverlay, #cboxWrapper {
    display: none !important;
    }
  }

</style>
