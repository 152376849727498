<template>
  <div>
    <a href="#top" class="scroll-top a-unstyled">
      <span class="fa fa-chevron-up"></span>
      <span class="hide">automatisch nach oben scrollen</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ScrollTopButton',
  mounted () {
    this.$nextTick(() => {
      $(document).ready(function () {
        var maxScrollPos = $('footer').offset().top - $(window).height()
        var posAfterWelcome = $('.welcome').offset().top + $('.welcome').outerHeight()

        $(window).scroll(function () {
          if ($(this).scrollTop() >= posAfterWelcome && $(window).width() > 600) {
            $('.scroll-top').fadeIn(200)
          } else {
            $('.scroll-top').fadeOut(200)
          }

          if ($(this).scrollTop() >= maxScrollPos) {
            $('.scroll-top').addClass('max-scroll')
          } else {
            $('.scroll-top').removeClass('max-scroll')
          }
        })

        $('.scroll-top').click(function () {
          $('body, html').animate({
            scrollTop: 0
          }, 1000)
        })
      })
    })
  }
}
</script>

<style lang="scss">
  @import '../assets/scss/base';

  .scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: $color-main;
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 35px;
    display: none;
    transition: all 0.2s linear;
    z-index: 3;

    &.max-scroll {
      position: absolute;
      top: -60px;
    }
  }

  .scroll-top span {
    color: $color-white;
    margin: 0;
    position: relative;
    left: 16px;
    top: 15px;
    font-size: 19px !important;
    transition: all 0.2s linear;
  }

  .scroll-top:hover {
    background: $color-main;
  }

  .scroll-top:hover span {
    color: $color-white;
    top: 8px;
  }

  //_____________________________________________________Smartphone
  @media (max-width: $screen-md) {
    .scroll-top {
      display: none !important;
    }
  }
</style>
