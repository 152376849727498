<template>
  <div>
    <header class="header" id="top">
      <div class="container">
        <div class="header-headlines">
          <h1 class="header-headline">{{contentHeader.Ueberschrift1}}</h1>
          <h2 class="header-headline2">{{contentHeader.Ueberschrift2}}</h2>
          <h2 class="header-headline2">{{contentHeader.Ueberschrift3}}</h2>
        </div>
        <picture class="header-picture">
          <source srcset="/img/Schleife_weiß.png" media="(min-width: 1000px)">
          <img src="" class="header-img" width="200" height="200" alt="Schleifen Icon in der Farbe Weiß">
        </picture>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['contentHeader']
}
</script>

<style scoped lang="scss">
@import '../assets/scss/base';

header {
  padding: 140px 0 300px;
  background-image: linear-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -webkit--webkit-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -webkit--moz-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -webkit--o-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -webkit--ms-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -webkit-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -moz--webkit-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -moz--moz-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -moz--o-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -moz--ms-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -moz-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -ms--webkit-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -ms--moz-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -ms--o-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -ms--ms-radial-gradient(180deg, $color-main 47%, $color-header 128%);
  // background-image: -ms-radial-gradient(180deg, $color-main 47%, $color-header 128%);
}

.header-img {
  position: absolute;
  right: 15px;
  height: auto;
  top: 10px;
  width: 240px;
}

.header-headlines {
  position: absolute;
  right: 380px;
  text-align: right;
  top: 60px;
}

.header-headline {
  color: $color-white-header;
  font-family: $font-header;
  font-size: 9em;
  font-weight: 400;
  line-height: 30px;
}

.header-headline2 {
  color: $color-white-header;
  font-family: $font-header;
  font-size: 3.2em;
  line-height: 26px;
  margin-top: -5px;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {

}
//_____________________________________________________Smartphone
@media (max-width: 1200px) {
    .header-headlines {
      left: 10%;
    }

    .header-img {
      right: -60px;
    }
}

@media (max-width: $screen-md) {
  header {
    padding: 200px 0 150px;
  }

  .header-img, .header-picture {
    display: none;
  }

  .header-headlines {
    position: static;
    right: auto;
    text-align: center;
    top: auto;
  }

  .header-headline {
    padding-bottom: 7px;
  }
}

//_____________________________________________________Smartphone
@media (max-width: $screen-md) {
  .header-headline {
    font-size: 7.5rem;
  }

  header {
    padding: 160px 0 110px;
  }
}

@media (max-width: $screen-sm) {
  header {
    padding: 100px 0 60px;
  }

  .header-headline {
    font-size: 4.5em;
  }

  .header-headline2 {
    font-size: 2.5em;
    margin-top: 10px;
  }
}
</style>
