<template>
    <div class="nav">
        <div class="nav-button">
        <button type="button" class="nav-burger-button button-unstyled" aria-pressed="false">
            <span></span>
            <span></span>
            <span></span>
            <p class="hide">Navigation aufklappen</p>
        </button>
        </div>
        <span class="nav-branding">Kranzschleifendruck</span>
        <img src="/img/Schleife_weiß_klein.png" class="nav-logo" width="200" height="200" alt="Schleifen Icon in der Farbe Weiß">
        <nav class="nav-container">
            <ul class="nav-main ul-unstyled right">
                <li v-for="item in contentNavigation" :key="item.Ueberschrift" class="nav-links">
                  <a :href="appendHashtag(item.Ueberschrift)" class="a-unstyled"><span>{{item.Ueberschrift}}</span></a>
                </li>
                <li class="nav-links link-impressum">
                  <a href="#Impressum" class="a-unstyled"><span>Impressum</span></a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: ['contentNavigation'],
  methods: {
    appendHashtag: function (str) {
      return ('#' + str).trim().replace(/\s/g, '')
    }
  },
  mounted () {
    this.$nextTick(() => {
      var navHeight = $('.nav').outerHeight()
      var footerPos = $('footer').offset().top
      var item = $('nav ul li')
      var brandingPos = $('header').outerHeight()

      // Burgerbutton
      $('.nav-burger-button').on('click', function () {
        $(this).toggleClass('close')
        $('.nav').toggleClass('show-nav')
        $('body').toggleClass('transparent-background')

        if ($(this).hasClass('close')) {
          $('.nav-burger-button').attr('aria-pressed', 'true')
        } else {
          $('.nav-burger-button').attr('aria-pressed', 'false')
        }

        $('.nav-links').on('click', function () {
          $('.nav-burger-button').removeClass('close')
          $('.nav').removeClass('show-nav')
          $('body').removeClass('transparent-background')
          $('.nav-burger-button').attr('aria-pressed', 'false')
        })

        // $('.nav-sublinks').on('click', function () {
        //   $('.nav-burger-button').removeClass('close')
        //   $('.nav').removeClass('show-nav')
        //   $('body').removeClass('transparent-background')
        //   $('.impressum-content').fadeIn(800)
        //   $('html, body').stop().animate({ scrollTop: positionImpressum }, 1000)
        // })
      })

      // oncklick nav -> scroll button
      item.on('click', 'a', function (event) {
        var section = $($(this).attr('href'))
        var sectionTop = section.offset().top - navHeight + 1
        $('html, body').stop().animate({ scrollTop: sectionTop }, 1000)
        event.preventDefault()
      })

      $(window).scroll(function () {
        var scrollTop = $(this).scrollTop()
        showActiveLink(scrollTop)
      })

      // show active navelement
      function showActiveLink (scrollTop) {
        if (scrollTop >= (brandingPos)) {
          $('.nav-branding').addClass('active')
        } else {
          $('.nav-branding').removeClass('active')
        }

        item.each(function () {
          var section = $($(this).find('a').attr('href'))
          var sectionTop = section.offset().top - navHeight
          var sectionHeight = section.height()

          if ((sectionTop) <= scrollTop && (sectionTop + sectionHeight) > scrollTop) {
            $(this).addClass('active')
            $(this).siblings().removeClass('active')
          }

          if (scrollTop <= (sectionTop)) {
            $(this).removeClass('active')
          }

          if (scrollTop >= (footerPos)) {
            $(this).removeClass('active')
          }
        })
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/scss/base';

.nav {
  background-color: $color-main;
  height: $nav-height;
  margin: 0 auto;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.nav-button,
.nav-logo,
.nav-branding {
  display: none;
}

.nav-burger-button {
  background-color: $color-main;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 60px;
  position: relative;
  width: 50px;

  &:focus {
    outline: none;
  }

  span {
    background: $color-white-header;
    border-radius: 100px;
    height: 3px;
    left: 11px;
    position: absolute;
    width: 55%;
  }

  span:nth-child(1) {
    top: 20px;
    transition: transform 0.2s, 0.2s all 0.4s;
  }

  span:nth-child(2) {
    margin-top: -1px;
    opacity: 1;
    top: 50%;
    transition: opacity 0.4s, visibility 0.4s;
    visibility: visible;
  }

  span:nth-child(3) {
    bottom: 20px;
    transition: transform 0.2s, 0.2s all 0.4s;
  }

  &.close span:nth-child(1) {
    transition: all 0.2s, 0.2s transform 0.4s;
    margin-top: -1px;
    top: 50%;
    transform: rotate(45deg);
  }

  &.close span:nth-child(2) {
    opacity: 0;
    transition: opacity 0.4s, visibility 0.4s;
    visibility: hidden;
  }

  &.close span:nth-child(3) {
    transition: all 0.2s, 0.2s transform 0.4s;
    bottom: 50%;
    margin-bottom: -1px;
    transform: rotate(-45deg);
  }
}

.nav-main {
  padding-right: 40px;

  &.nav-submain {
    background-color: $color-accordion-background;
    display: none;
    padding: 10px 0;
  }

  >.nav-links {
    display: inline-block;
    font-family: $font-nav;

    &:hover, &.active {
      a > span {
        border-bottom: 2px solid $color-white;
        display: inline-block;
        line-height: 30px;
      }
    }

    &.nav-sublinks {
      background-color: $color-accordion-background;

      a {
        border: 0;
      }
    }

    >a {
      color: $color-white;
      font-size: 1.8rem;
      line-height: $nav-height;
      padding: 0 10px;
    }
  }
}

//_____________________________________________________Tablet
@media (max-width: $screen-lg) {}

//_____________________________________________________Smartphone
@media (max-width: $screen-md) {
  .nav {
    background-color: $color-main;
    top: 0;
    width: 100%;
  }

  .nav.show-nav {
    .nav-container {
      margin: 0;
    }
  }

  .nav-logo {
    display: inline-block;
    height: auto;
    position: absolute;
    right: 0;
    top: 11px;
    width: 55px;
  }

  .nav-branding {
    color: $color-white-header;
    display: inline-block;
    font-family: $font-header, $font-default;
    font-size: 3rem;
    position: absolute;
    top: 14px;
    right: 0;
    left: 0;
    margin: 0 60px;
    visibility: hidden;
    text-align: center;

    &.active {
      visibility: visible;
    }
  }

  .nav-container {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0,0.24);
    display: block;
    margin-left: -100%;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
    right: 60px;
    // transition: margin .4s cubic-bezier(.4,0,.2,1);
    transition: margin 0.4s cubic-bezier(0.36, 0.04, 0.1, 1.13);
    width: 100%;
  }

  .nav-main {
    text-align: left;
    width: 100%;
    padding-right: 0;

    &.nav-submain {
      display: table;
    }

    > .nav-links {
      background-color: $color-background2;
      display: block;

      &:first-child {
        padding-top: 10px;
      }

      &:last-child {
        padding-bottom: 15px;
      }

      >a {
        color: $color-fliestext;
        display: block;
        font-size: 1.7rem;
        line-height: 45px;
        padding-left: 80px;
      }

      //reset des unterstreichen
      &:hover {
        a > span {
          border: 0;
          line-height: 30px;
        }
      }

      &.active {
        a > span {
          border-bottom: 2px solid $color-main;
          line-height: 30px;
        }
      }

      &:nth-child(n) {
        width: 100%;
      }
    }
  }

  .nav-button {
    display: inherit;
  }
}

</style>
