<template>
  <div id="Impressum" class="impressum">
    <div class="impressum-header">
      <span class="fa fa-chevron-down"></span>
      <p class="p-unstyled">Impressum</p>
    </div>
    <div class="impressum-content">
      <div class="container">
        <h2 class="text-center">Impressum</h2>
        <p class="p-unstyled fliestext" v-html="impressumContent.Inhalt"></p>
      </div> <!-- container -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impressum',
  props: ['impressumContent'],
  mounted () {
    this.$nextTick(() => {
      // var navHeight = $('.nav').outerHeight()
      var positionImpressum = 0
      var scrollPostionClose = 0
      var scrollPostionCloseMobile = 0

      function toggleImpressum (toggle) {
        positionImpressum = $('footer').offset().top + $('footer').outerHeight()
        scrollPostionClose = $('.impressum-content').offset().top - $('.impressum-content').outerHeight() - $('footer').outerHeight()
        scrollPostionCloseMobile = $('.impressum-header').offset().top - $('.impressum-header').outerHeight() - 190

        if (toggle) {
          $('.impressum-content').fadeToggle(1200).toggleClass('active')
          // $('.impressum-content').toggleClass('active')
          if ($('.impressum-content').hasClass('active')) {
            $('.impressum-header .fa').removeClass('fa-chevron-down')
            $('.impressum-header .fa').addClass('fa-chevron-up')

            if ($(window).width() > 600) {
              $('html, body').stop().animate({ scrollTop: positionImpressum }, 1000)
            } else {
              $('html, body').stop().animate({ scrollTop: positionImpressum }, 1000)
            }
          } else {
            $('.impressum-header .fa').removeClass('fa-chevron-up')
            $('.impressum-header .fa').addClass('fa-chevron-down')

            if ($(window).width() > 600) {
              $('html, body').stop().animate({ scrollTop: scrollPostionClose }, 1000)
            } else {
              $('html, body').stop().animate({ scrollTop: scrollPostionCloseMobile }, 1000)
            }
          }
        } else {
          $('.impressum-content').fadeIn(800)
        }
      }

      $('.impressum-header').on('click', function () {
        toggleImpressum(true)
      })

      $('.link-impressum').on('click', function () {
        toggleImpressum(false)
      })
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/scss/base';

  .impressum-header {
    background-color: $color-main;
    border-top: 2px solid $color-white;
    cursor: pointer;
    display: block;
    padding: 5px 0;
    text-align: center;

    span {
      color: white;
      font-size: 12px;
      padding-right: 10px;
    }

    p {
      color: $color-white;
      font-size: 1.8rem;
      display: inline-block;
    }
  }

  .impressum-content {
    padding: 40px 0;
    display: none;
  }
</style>
