<template>
  <div>
      <section class="main-content welcome">
        <div class="container">
          <h2 class="text-center">{{introContent.Ueberschrift}}</h2>
          <div v-for="item in introContent.Inhalt" :key="item.ID">
            <p class="fliestext" v-if="item.Typ == 'Fliesstext'" v-html="item.Text"></p>
            <ul v-if="item.Typ == 'ListeMitSymbol'">
              <li v-for="listel in item.Elemente" :key="listel">{{listel}}</li>
            </ul>
            <ol v-if="item.Typ == 'ListeNummeriert'">
              <li v-for="listel in item.Elemente" :key="listel">{{listel}}</li>
            </ol>
          </div>
        </div> <!-- container -->
      </section> <!-- main-content-->
  </div>
</template>

<script>
export default {
  name: 'IntroSection',
  props: ['introContent']
}
</script>

<style lang="scss">

</style>
