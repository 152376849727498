import jQuery from 'jquery'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'jquery-colorbox/example3/colorbox.css'
import Vue from 'vue'
import App from './App.vue'

global.jQuery = jQuery
global.$ = jQuery

require('jquery-colorbox')

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
